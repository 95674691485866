// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Abel);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{\n    margin: 0;\n}\ntable {\n    margin: 0 auto; /* Center the table on the page */\n    border-collapse: collapse; /* Remove the default cell spacing */\n    font-family: 'Abel'; /* Set the font for the table */\n    width: 80%; /* Set the width of the table */\n  }\n  \n  th, td {\n    border: 1px solid black; /* Add a border to the cells */\n    padding: 8px; /* Add some padding to the cells */\n  }\n  \n  th {\n    background-color: lightgrey; /* Give the header cells a light grey background */\n    font-weight: bold; /* Make the header text bold */\n  }\n  \n", "",{"version":3,"sources":["webpack://./src/pages/MyBids.css"],"names":[],"mappings":"AAEA;IACI,SAAS;AACb;AACA;IACI,cAAc,EAAE,iCAAiC;IACjD,yBAAyB,EAAE,oCAAoC;IAC/D,mBAAmB,EAAE,+BAA+B;IACpD,UAAU,EAAE,+BAA+B;EAC7C;;EAEA;IACE,uBAAuB,EAAE,8BAA8B;IACvD,YAAY,EAAE,kCAAkC;EAClD;;EAEA;IACE,2BAA2B,EAAE,kDAAkD;IAC/E,iBAAiB,EAAE,8BAA8B;EACnD","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Abel');\n\nbody{\n    margin: 0;\n}\ntable {\n    margin: 0 auto; /* Center the table on the page */\n    border-collapse: collapse; /* Remove the default cell spacing */\n    font-family: 'Abel'; /* Set the font for the table */\n    width: 80%; /* Set the width of the table */\n  }\n  \n  th, td {\n    border: 1px solid black; /* Add a border to the cells */\n    padding: 8px; /* Add some padding to the cells */\n  }\n  \n  th {\n    background-color: lightgrey; /* Give the header cells a light grey background */\n    font-weight: bold; /* Make the header text bold */\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
