// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Cinzel);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Abel);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vertical{\n    width: 55vmin;\n    height:70vmin;\n}   \n\n/* .image3{\n    width: 55vmin;\n    height:45vmin;\n}    */\n\n.horizontal{\n    width: 55vmin;\n    height:45vmin;\n}   \n\n.swal2-popup {\n    font-family: \"Abel\";\n  }\n\n.buttonbid{\n\n        height:40px;\n        width:90px;\n        font-size: 15px;\n      }\n\n.button2{\n    justify-content: center;\n    height:40px;\n    \n    }\n\np1{\n        color:rgb(236, 230, 230);\n        font-style: bold;\n        font-family: 'Abel';\n\n    }\n\np{\n    font-family: \"Abel\";\n}\n\n.colortext{\n\n    color:rgb(103, 15, 15);\n    padding:0;\n    font-family: \"Abel\";\n}\n\n.blacktext{\n    color:black;\n}", "",{"version":3,"sources":["webpack://./src/component/ImageDisplay.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,aAAa;AACjB;;AAEA;;;MAGM;;AAEN;IACI,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,mBAAmB;EACrB;;AAEF;;QAEQ,WAAW;QACX,UAAU;QACV,eAAe;MACjB;;AAEN;IACI,uBAAuB;IACvB,WAAW;;IAEX;;AAEJ;QACQ,wBAAwB;QACxB,gBAAgB;QAChB,mBAAmB;;IAEvB;;AAEJ;IACI,mBAAmB;AACvB;;AAEA;;IAEI,sBAAsB;IACtB,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Cinzel');\n@import url('https://fonts.googleapis.com/css?family=Abel');\n.vertical{\n    width: 55vmin;\n    height:70vmin;\n}   \n\n/* .image3{\n    width: 55vmin;\n    height:45vmin;\n}    */\n\n.horizontal{\n    width: 55vmin;\n    height:45vmin;\n}   \n\n.swal2-popup {\n    font-family: \"Abel\";\n  }\n\n.buttonbid{\n\n        height:40px;\n        width:90px;\n        font-size: 15px;\n      }\n\n.button2{\n    justify-content: center;\n    height:40px;\n    \n    }\n\np1{\n        color:rgb(236, 230, 230);\n        font-style: bold;\n        font-family: 'Abel';\n\n    }\n\np{\n    font-family: \"Abel\";\n}\n\n.colortext{\n\n    color:rgb(103, 15, 15);\n    padding:0;\n    font-family: \"Abel\";\n}\n\n.blacktext{\n    color:black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
